import { request } from './request'

// 登录
export function loginApi(data) {
  return request({
    url: '/txapi/login',
    method: 'get',
    params: data
  })
}

// 注册
export function registerApi(data) {
  return request({
    url: '/txapi/addUser',
    method: 'post',
    data: data
  })
}

// 找回密码
export function findPasswordApi(data) {
  return request({
    url: '/txapi/updateUser',
    method: 'post',
    data: data
  })
}

// 发送短信
export function sendSMSApi(data) {
  return request({
    url: '/txapi/SendProviderSms',
    method: 'post',
    data: data
  })
}