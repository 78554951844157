<template>
  <div>
  <Modal v-model="modal2" width="460">
      <p slot="header" style="color:#f60;text-align:center;font-size: 16px;">
          <Icon type="ios-information-circle"></Icon>
          <span>重 要 通 知</span>
      </p>
      <div style="text-align:left;font-size: 18px;color: #000;">
          <p style="line-height:30px;text-indent:2em;">因原长江报价将于元旦后停发。为了不影响客户采用，同鑫公司已按原长江采价渠道和定价机制在元旦后推出同鑫现货报价。实际报价完全等同和替代原长江报价，以供客户结算参考，欢迎客户免费使用</p>
          <p></p>
      </div>
      <div slot="footer">
          <Button type="error" size="large" long :loading="modal_loading" @click="confirmbtn">确定</Button>
      </div>
  </Modal>
    <div class="swier">
      <Carousel autoplay loop :autoplay-speed="3000">
        <CarouselItem>
          <img src="~assets/img/banner5.png" style="width: 100%" />
        </CarouselItem>
        <CarouselItem>
          <img src="~assets/img/banner.png" style="width: 100%" />
        </CarouselItem>
        <CarouselItem>
          <img src="~assets/img/banner2.png" style="width: 100%" />
        </CarouselItem>
        <CarouselItem>
          <img src="~assets/img/banner3.png" style="width: 100%" />
        </CarouselItem>
        <CarouselItem>
          <img src="~assets/img/banner4.png" style="width: 100%" />
        </CarouselItem>
      </Carousel>
    </div>
    <div class="w1400">
      <div class="homeone clearfix">
        <div class="fl w960">
          <div class="mbxstyle">
            <img src="~assets/img/hqjg.png" style="width: 26px; height: 34px" />
            <span class="mbx_title">行情价格</span>
            <span class="mbx_more" @click="setPageCurryIndex('/marketprice')"
              >更多>></span
            >
          </div>
          <ul class="nametitle">
            <li
              v-for="(hjjgarr, index) in hjjgarrs"
              @click="changes(index, hjjgarr.id)"
              v-bind:class="{ active: index == current }"
            >
              {{ hjjgarr.name }}
            </li>
          </ul>
          <Table
            style="margin-top: 30px"
            :columns="columns1"
            :data="data2"
            stripe
          ></Table>
        </div>
        <div class="fr w420">
          <div class="mbxstyle">
            <img src="~assets/img/fgtj.png" style="width: 34px; height: 33px" />
            <span class="mbx_title">废钢调价</span>
            <span class="mbx_more" @click="setPageCurryIndex('/scrapprice')"
              >更多>></span
            >
          </div>
          <ul class="tiaojiabody" style="height: 460px">
            <li
              class="clearfix"
              v-for="(fgtjarr, index) in fgtjarrs"
              :id="fgtjarr.subId"
              :title="fgtjarr.title"
              @click="gcchanges(fgtjarr.subId)"
            >
              <span class="fl"
                ><strong>[{{ fgtjarr.name }}]</strong>&nbsp;&nbsp;{{
                  fgtjarr.title
                }}</span
              >
              <span class="fr">{{ fgtjarr.date }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="advertisement">
        <img src="~assets/img/home/gg1.png" alt="" />
        <img src="~assets/img/home/gg2.png" alt="" />
        <img src="~assets/img/home/gg3.png" alt="" />
      </div>
      <div class="advertisement">
        <img src="~assets/img/home/ad1.png" alt="" />
        <img src="~assets/img/home/ad2.png" alt="" />
        <img src="~assets/img/home/ad3.png" alt="" />
      </div>
      <!-- <div class="clearfix" style="margin-top: 40px;">
        <div class="fl ">
          <img class="w960" src="~assets/img/gg1.jpg"/>
        </div>
        <div class="fr">
          <img class="w420" src="~assets/img/gg2.jpg"/>
        </div>
      </div> -->
      <div class="cheliang clearfix">
        <div class="fl w960">
          <div class="mbxstyle">
            <img src="~assets/img/clss.png" style="width: 27px; height: 35px" />
            <span class="mbx_title">车辆实时地图</span>
            <div id="myChart" class="fl w960" style="height: 495px"></div>
          </div>
        </div>
        <div class="fr w420">
          <div class="mbxstyle">
            <img src="~assets/img/cldt.png" style="width: 36px; height: 34px" />
            <span class="mbx_title">车辆动态</span>
          </div>
          <div class="shuju">
            <div class="clearfix">
              <div class="fl zaitu">
                <span>{{ cargrpsnumber }}</span>
                <span>在途车/船次</span>
              </div>
              <div class="fl leiji">
                <span>{{ carzaitunumber }}</span>
                <span>累计车/船次</span>
              </div>
            </div>
            <div class="scrolllist">
              <div class="clearfix title">
                <span>车/船牌号</span><span>净重(吨)</span><span>在运状态</span>
              </div>
              <vue-seamless-scroll
                :data="cararrs"
                style="height: 325px; overflow: hidden"
                :class-option="defaultOption"
              >
                <ul class="item">
                  <li
                    class="clearfix"
                    v-for="item in cararrs"
                    :class="{ active: item.logisticType == 2 }"
                  >
                    <span>{{ item.plateNumber }}</span
                    ><span>{{ item.netWeight }}</span
                    ><span>{{ item.logisticStatus }}</span>
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="gongqiu clearfix">
        <div class="mbxstyle">
          <img src="~assets/img/gqsc.png" style="width: 34px; height: 34px" />
          <span class="mbx_title">供求市场</span>
          <span class="mbx_more" @click="setPageCurryIndex('/supplyMarket')"
            >更多>></span
          >
        </div>
        <ul class="gongqiulist clearfix">
          <li
            class="clearfix"
            v-for="(gqscarr, index) in gqscarrs"
            @click="selfset(gqscarr.quota, gqscarr.account)"
          >
            <img
              v-if="gqscarr.images == null"
              style="
                width: 140px;
                height: 128px;
                float: left;
                margin-right: 10px;
              "
              src="~assets/img/zwtp.png"
            />
            <img
              v-else
              style="
                width: 140px;
                height: 128px;
                float: left;
                margin-right: 10px;
              "
              :src="'http://172.20.67.119:3006/images/thumb/' + gqscarr.images"
            />
            <div class="fl moresell" :id="gqscarr.quota">
              <span
                :title="gqscarr.description"
                style="max-height: 50px; overflow: hidden"
                ><strong
                  >[{{ gqscarr.tradeType }}]{{ gqscarr.description }}</strong
                ></span
              >
              <span>单价：{{ gqscarr.price }}/吨</span>
              <span>数量：{{ gqscarr.quantity }}吨</span>
              <span><img src="~assets/img/dw.jpg" />{{ gqscarr.name }}</span>
            </div>
          </li>
        </ul>
      </div> -->
      <div class="zhuangtai clearfix">
        <div class="mbxstyle">
          <img src="~assets/img/cjdt.png" style="width: 36px; height: 34px" />
          <span class="mbx_title">成交动态</span>
        </div>
        <vue-seamless-scroll
          :data="prizeList"
          style="
            height: 128px;
            border: 1px solid #e6ebf5;
            overflow: hidden;
            padding: 8px;
          "
          :class-option="defaultOption"
        >
          <div>
            <ul class="zhuangtailist clearfix">
              <li class="clearfix" v-for="item in prizeList">
                <span
                  class="fl"
                  style="
                    width: 125px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  :title="
                    item.contactName + '成功' + item.tradeType + item.cateName
                  "
                  >{{ item.contactName }}成功{{ item.tradeType
                  }}{{ item.cateName }}</span
                >
                <span class="fr">{{ item.created.substring(5, 16) }}</span>
              </li>
            </ul>
          </div>
        </vue-seamless-scroll>
      </div>
      <div class="secondbody">
        <ul class="clearfix secondlist">
          <li>
            <div class="mbxstyle">
              <img
                src="~assets/img/wzpm.png"
                style="width: 34px; height: 34px"
              />
              <span class="mbx_title">物资拍卖</span>
              <span class="mbx_more" @click="setPageCurryIndex('/bidAuction')"
                >更多>></span
              >
            </div>
            <ul class="tiaojiabody">
              <li
                class="clearfix"
                v-for="(wzpmarr, index) in wzpmarrs"
                :id="wzpmarr.id"
                :title="wzpmarr.title"
                @click="
                  bidAuctionclick(
                    wzpmarr.id,
                    wzpmarr.page,
                    wzpmarr.subjectId,
                    wzpmarr.marketId,
                    'BidAuctionDel'
                  )
                "
              >
                <span class="fl"
                  ><strong>[{{ wzpmarr.name }}]</strong
                  >{{ wzpmarr.title }}</span
                >
                <span class="fr" style="color: #25449a"
                  ><img src="~assets/img/dw.jpg" />&nbsp;&nbsp;{{
                    wzpmarr.city
                  }}</span
                >
              </li>
            </ul>
          </li>
          <li style="margin: 0 16px">
            <div class="mbxstyle">
              <img
                src="~assets/img/fzzd.png"
                style="width: 37px; height: 34px"
              />
              <span class="mbx_title">分析指导</span>
              <span class="mbx_more" @click="setPageCurryIndex('/analysis')"
                >更多>></span
              >
            </div>
            <ul class="tiaojiabody">
              <li
                class="clearfix"
                v-for="(fxzdarr, index) in fxzdarrs"
                :id="fxzdarr.id"
                :title="fxzdarr.title"
                @click.stop="handleImgClick(
                    fxzdarr.id,
                    fxzdarr.page,
                    fxzdarr.subjectId,
                    fxzdarr.marketId
                    )"
              >
                <span class="fl"
                  ><strong>[{{ fxzdarr.marketName }}]</strong>&nbsp;&nbsp;{{
                    fxzdarr.title
                  }}</span
                >
                <span class="fr">{{ fxzdarr.date }}</span>
              </li>
            </ul>
          </li>
          <li>
            <div class="mbxstyle">
              <img
                src="~assets/img/hyzx.png"
                style="width: 29px; height: 34px"
              />
              <span class="mbx_title">行业资讯</span>
              <span class="mbx_more" @click="setPageCurryIndex('/industryInfo')"
                >更多>></span
              >
            </div>
            <ul class="tiaojiabody">
              <li
                class="clearfix"
                v-for="(hyzxarr, index) in hyzxarrs"
                id="hyzxarr.id"
                :title="hyzxarr.title"
                @click="
                  IndustryDetailclick(
                    hyzxarr.id,
                    hyzxarr.page,
                    hyzxarr.subjectId
                  )
                "
              >
                <span class="fl"
                  ><strong>[{{ hyzxarr.marketName }}]</strong>&nbsp;&nbsp;{{
                    hyzxarr.title
                  }}</span
                >
                <span class="fr">{{ hyzxarr.date }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="zhuangtai clearfix">
        <div class="mbxstyle">
          <img src="~assets/img/hzhb.png" style="width: 40px; height: 35px" />
          <span class="mbx_title">合作伙伴</span>
        </div>
      </div>
    </div>
    <div style="background:#FAFAFA;padding: 20px 0;margin-bottom: 150px">
      <ul class="hehblist">
        <li>
          <a href="http://www.yong-gang.com/portal/" target="_BLANK"
          ><img
            weight="220"
            height="220"
            src="~assets/img/hzhbimg/27@2x.png"
          /></a>
        </li>
        <li>
          <a href="http://www.njsteel.com.cn/" target="_BLANK"
          ><img
            weight="220"
            height="220"
            src="~assets/img/hzhbimg/28@2x.png"
          /></a>
        </li>
        <li>
          <a href="https://www.rizhaosteel.com/" target="_BLANK"
          ><img
            weight="220"
            height="220"
            src="~assets/img/hzhbimg/29@2x.png"
          /></a>
        </li>
        <li>
          <a href="http://www.dalipal.com/" target="_BLANK"
          ><img
            weight="220"
            height="220"
            src="~assets/img/hzhbimg/30@2x.png"
          /></a>
        </li>
        <li>
          <a href="http://www.zt.net.cn/" target="_BLANK"
          ><img
            weight="220"
            height="220"
            src="~assets/img/hzhbimg/32@2x.png"
          /></a>
        </li>
      </ul>
      <ul class="hehblist" style="margin-top: -78px">
        <li>
          <a href="http://www.gyxcty.com/index.html" target="_BLANK"
          ><img
            weight="220"
            height="220"
            src="~assets/img/hzhbimg/33@2x.png"
          /></a>
        </li>
        <li>
          <a href="http://www.nbjinlongcopper.com/" target="_BLANK"
          ><img
            weight="220"
            height="220"
            src="~assets/img/hzhbimg/34@2x.png"
          /></a>
        </li>
        <li>
          <a href="http://www.jtgroup.com.cn/welcome" target="_BLANK"
          ><img
            weight="220"
            height="220"
            src="~assets/img/hzhbimg/35@2x.png"
          /></a>
        </li>
        <li>
          <a href="http://al.tjjinpeng.com/" target="_BLANK"
          ><img
            weight="220"
            height="220"
            src="~assets/img/hzhbimg/36@2x.png"
          /></a>
        </li>
      </ul>
      <ul class="hehblist" style="margin-top: -78px">
        <li>
          <a href="http://www.sfszgkj.cn/" target="_BLANK"
          ><img
            weight="220"
            height="220"
            src="~assets/img/hzhbimg/37@2x.png"
          /></a>
        </li>
        <li>
          <a href="http://www.fgdbj.com/" target="_BLANK"
          ><img
            weight="220"
            height="220"
            src="~assets/img/hzhbimg/38@2x.png"
          /></a>
        </li>
        <li>
          <a href=" http://www.jyhongly.cn/" target="_BLANK"
          ><img
            weight="220"
            height="220"
            src="~assets/img/hzhbimg/39@2x.png"
          /></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import NavHead from '@/components/content/mainTabbar/NavHead'
import LogoBar from '@/components/content/mainTabbar/LogoBar'
import 'assets/js/china.js'
import {
  getPriceNewsApi,
  getAuctionNewsApi,
  getItemsApi,
  getUserOrdersApi,
  homeInfo,
  selectMarketPriceHomePageApi,
} from 'network/home'
import { loginApi } from 'network/login'
export default {
  name: 'Home',
  data() {
    var self = this // 存储当前vue实例this,这行是重
    return {
      userName:"",
      setVip: '',
      token: '',
      modal2: false,
      value2: 0,
      hjjgarrs: [
        { name: '基本金属', id: 1 },
        { name: '废旧金属', id: 2 },
        { name: '废铜', id: 114 },
        { name: '废钢', id: 4 },
        { name: '废纸', id: 101 },
        { name: '废铅市场', id: 111 },
        { name: '建材', id: 5 },
        { name: '不锈钢', id: 6 },
        { name: '三元催化', id: 123 },
      ], //行情价格
      hjjgarrone: 1, //行情价格默认第一个id
      current: 0, //行情价格默认第一个
      market_title : '市场',
      columns1: [
        {
          renderHeader: (h, params) => {
            return h(
              'span',
              {
                style: {
                  marginLeft: '20px',
                },
              },
              '产品名称'
            )
          },
          align: 'left',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  style: {
                    display: 'inline-block',
                    height: '100%',
                    color: '#25449a',
                    paddingLeft: '20px',
                    textDecoration: 'underline',
                  },
                  on: {
                    click: () => {
                      if (self.token) {
                        if (self.setVip == 1) {
                          self.$router.push({
                            path: '/marketdetail',
                            query: {
                              productId: params.row.productId,
                              marketId: params.row.marketId,
                              // shopname:1,
                              // branchname:1
                            },
                          })
                        } else {
                          self.$Modal.warning({
                            title: '提示',
                            content:
                              '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
                          })
                        }
                      } else {
                        self.$router.push({ path: '/login' })
                      }
                    },
                  },
                },
                params.row.productName
              ),
            ])
          },
        },
        {
          title: '分类',
          align: 'center',
          render: (h, p) => {
            return h(
              'span',
              {
                style: {
                  color: '#000',
                },
              },
              p.row.parentName
            )
          },
        },
        {
          title: '价格',
          align: 'center',
          render: function (h, params) {
            if ((self.token && self.setVip == 1) || self.hjjgarrone == 1 || 1==1 ) {
              return h(
                'div',
                {
                  style: {
                    color: '#000',
                  },
                },
                [
                  h('span', {}, params.row.lprice),
                  h('span', {}, '-'),
                  h('span', {}, params.row.hprice),
                ]
              )
            } else {
              return h(
                'div',
                {
                  style: {
                    color: '#000',
                  },
                },
                '****'
              )
            }
          },
        },
        {
          title: '涨跌',
          align: 'center',
          render: function (h, params) {
            if ((self.token && self.setVip == 1) || self.hjjgarrone == 1 || 1==1 ) {
              let text = params.row.priceChange.substring(0, 1)
              let texts = params.row.priceChange.substring(
                1,
                params.row.priceChange.length
              )
              let num = params.row.priceChange.substring(1)
              var imgstyle
              var textstyle
              if (text == '涨') {
                imgstyle = 'up'
                textstyle = 'red'
              } else if (text == '跌') {
                imgstyle = 'down'
                textstyle = 'green'
              }
              if (num == 0) {
                imgstyle = ''
                textstyle = ''
                texts = '平'
              }
              return h('div', [
                h(
                  'span',
                  {
                    style: {
                      color: '#000',
                    },
                    class: [imgstyle],
                  },
                  ''
                ),
                h(
                  'span',
                  {
                    style: {
                      color: '#000',
                    },
                    class: [textstyle],
                  },
                  texts
                ),
              ])
            } else {
              return h(
                'div',
                {
                  style: {
                    color: '#000',
                  },
                },
                '****'
              )
            }
          },
        },
        {
          renderHeader: (h, params) => {
          if ( self.hjjgarrone == 1 )
            this.market_title = "均价"
            else
             this.market_title = "市场"
          return h(
            'span',
            {
              style: {
                marginLeft: '20px',
              },
            },
             this.market_title
          )
          },
          align: 'center',
          render: (h, p) => {
            let m_txt = p.row.marketName;
            if ( self.hjjgarrone == 1 )
              m_txt = p.row.aprice;
            return h(
              'span',
              {
                style: {
                  color: '#000',
                },
              },
              m_txt
            )
          },
        },
        {
          title: '时间',
          key: '',
          align: 'center',
          render: function (h, params) {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#000',
                  },
                },
                params.row.addDate.slice(5, 16)
              ),
            ])
          },
        },
        {
          title: '图表',
          key: 'address',
          align: 'center',
          render: function (h, params) {
            return h('div', [
              h(
                'span',
                {
                  props: {
                    type: 'error',
                    size: 'small',
                  },
                  style: {},
                  class: ['tubiao'],
                  on: {
                    click: function () {
                      if (self.token) {
                        if (self.setVip == 1) {
                          self.$router.push({
                            path: '/marketdetail',
                            query: {
                              productId: params.row.productId,
                              marketId: params.row.marketId,
                              // shopname:1,
                              // branchname:1
                            },
                          })
                        } else {
                          self.$Modal.warning({
                            title: '提示',
                            content:
                              '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
                          })
                        }
                      } else {
                        self.$router.push({ path: '/login' })
                      }
                    },
                  },
                },
                ''
              ),
            ])
          },
        },
      ], //行情价格表格title
      data2: [], //行情价格表格表格数据
      fgtjarrs: [], //废钢调价数据
      wzpmarrs: [], //物资拍卖
      fxzdarrs: [], //分析指导
      hyzxarrs: [], // 行业资讯
      gqscarrs: [], // 供求市场
      mydata: [
        {
          gps: { lng: 119.944229, lat: 37.145195, t: 1615519405824 },
          netWeight: 18.71,
          plateNumber: '鲁FBN333',
          username: '青岛浩远睿通再生资源有限公司',
        },
      ],
      prizeList: [], //成交动态
      cararrs: [], //车辆动态
      cargrps: [], //车辆位置
      cargrpsnumber: 0,
      carzaitunumber: 0,
      activeIndex: 0,
      intnum: undefined,
    }
  },
  components: {
    NavHead,
    LogoBar,
  },
  mounted() {
    this.drawLine()
  },
  computed: {
    defaultOption() {
      return {
        step: 0.6, // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        // hoverStop: true, // 是否开启鼠标悬停stop
        // direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    },
    top() {
      return -this.activeIndex * 28 + 'px'
    },
  },
  created() {
    this.token = this.$store.state.token
    this.setVip = localStorage.getItem('setVip')
    this.userName=localStorage.getItem('userName')
    this.selectMarketPriceHomePage(this.hjjgarrone)
    this.ScrollUp(),
    this.getPriceNews(),
    this.getAuctionNewswz(),
    this.getAuctionNewsfx(),
    this.getAuctionNewshy(),
    this.getItems(),
    this.getUserOrders(),
    this.homeInfocar(),
    this.homeInfocar1(),
    this.homeInfomap()

    setTimeout(() => {
          this.modal2 = false;
    }, 15000);
    // this.$Message.warning({
    //   background: true,
    //   content: '因原长江报价将于元旦后停发。为了不影响客户采用，同鑫公司已按原长江采价渠道和定价机制在元旦后推出同鑫现货报价。实际报价完全等同和替代原长江报价，以供客户结算参考，欢迎客户免费使用',
    //   duration: 5
    // });
     //this.$Notice.warning({
    //   title: '提示',
    //   desc:"因原长江报价将于元旦后停发。为了不影响客户采用，同鑫公司已按原长江采价渠道和定价机制在元旦后推出同鑫现货报价。实际报价完全等同和替代原长江报价，以供客户结算参考，欢迎客户免费使用",
    //   duration: 5
    // })
     //this.$Modal.warning({
    //      title: "提示",
    //      content: "因原长江报价将于元旦后停发。为了不影响客户采用，同鑫公司已按原长江采价渠道和定价机制在元旦后推出同鑫现货报价。实际报价完全等同和替代原长江报价，以供客户结算参考，欢迎客户免费使用"
    //  });
    //  setTimeout(() => {
    //      this.$Modal.remove();
    //  }, 15000);
  },
  methods: {
    // 设置导航栏 active 类
    setPageCurryIndex(path) {
      if (this.token) {
        this.$router.push({ path })
      } else {
        if(path == '/supplyMarket' || path == '/industryInfo' || path == '/marketprice'){
          this.$router.push({ path })
        } else {
          this.$router.push({ path: '/login' })
        }
      }
    },
    changes(index, id) {
      //console.log(index, id);
      this.current = index
      this.hjjgarrone = id
      if ( id == 1 )
       this.market_title = "均价"
      else
       this.market_title = "市场"
      this.selectMarketPriceHomePage()
    },
    drawLine() {
      var datalist = this.cargrps
      var res = []
      for (var i = 0; i < datalist.length; i++) {
        var name = datalist[i].plateNumber
        var datalistarr = JSON.parse(datalist[i].gps)
        var value = [
          datalistarr[datalistarr.length - 1].lng,
          datalistarr[datalistarr.length - 1].lat,
        ] //新值= 坐标+旧值；
        var sz = { name: name, value: value }
        res.push(sz)
      }
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
        // backgroundColor: '#FFFFFF',
        // series: [{
        //   type: 'map',
        //   mapType: 'china',
        //   roam: true, // 是否开启鼠标缩放和平移漫游
        //   label: {
        //     normal: {
        //       show: true,//显示省份标签
        //       textStyle:{color:"#696969"}//省份标签字体颜色
        //     },
        //     emphasis: {//对应的鼠标悬浮效果
        //       show: true,
        //       textStyle:{color:"blue"}
        //     }
        //   },
        //   itemStyle: {
        //     normal: {
        //       borderWidth: .5,//区域边框宽度
        //       borderColor: '#FFFFFF',//区域边框颜色
        //       areaColor:"#DCDCDC",//区域颜色
        //
        //     },
        //     emphasis: {
        //       borderWidth: .10,
        //       borderColor: '#4b0082',
        //       areaColor:"#DCDCDC",
        //     }
        //   },
        //   aspectScale:0.75,//这个参数用于 scale 地图的长宽比。最终的 aspect 的计算方式是：geoBoundingRect.width / geoBoundingRect.height * aspectScale
        zoom: 1.5, //当前视角的缩放比例。
        //   data:res
        // }]
        backgroundColor: '#FFFFFF',
        geo: {
          map: 'china',
          label: {
            normal: {
              show: true, //关闭省份名展示
              fontSize: '10',
              color: '#fff',
            },
            emphasis: {
              show: true,
              textStyle: { color: '#696969' },
            },
          },
          nameMap: {
            selectedMode: false,
          },
          roam: true,
          // layoutCenter: ['50%', '27%'],
          layoutSize: '100%',
          itemStyle: {
            // 省块样式
            normal: {
              show: true,
              color: '#E5E3EF',
              borderColor: '#FFFFFF',
            },
            emphasis: {
              // 鼠标悬浮后的样式
              areaStyle: {
                color: '#fff', //选中状态的地图板块颜色
              },
            },
          },
        },
        series: {
          type: 'scatter', // 已到达
          coordinateSystem: 'geo', // 坐标系: cartesian2d 笛卡尔 polar 极坐标系 geo 地理坐标系
          zlevel: 2,
          rippleEffect: {
            period: 4,
            brushType: 'stroke',
            scale: 3,
          },
          label: {
            normal: {
              show: true,
              position: 'right',
              color: '#00ffff',
              formatter: '{b}',
              textStyle: {
                color: '#000',
              },
            },
            emphasis: {
              show: true,
            },
          },
          // symbol: 'circle',
          symbol:
            'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAACJklEQVQoU22SzWsTURTFz32TFEWEKi6kKEjJDBhB1IUuUkWbmYoiWhdRV4LYTPoP+NFFaEuQqvgHdCZKwZWahVUUMTOpoM3CLlQEI8wLXfhRupAqiFia5F2ZSSIufPA+ONzDue/3HqEzkpkHPY3vP/IAZwCxpS2rbwCV4pt6C7XSmbVQoXDpt6Z1jUU1rCAgT6Tehjqz2MtAAYBokUoteqOSdhye6Y3HGhLAG+nbR7uJ/+666T4HsK/RjOuUSDvXiOii9O2tYZGRdoaZaCqKZx4LKrnZ8Kyb7jIz3yHddD4BYlz62ZmE5Z4kxiMA+U5CgQmn6p79WDeLFwA1SYbprjBhUHr2O910awCeSt++FBoS6eItIj4ufTupW+4eYsyRbrpfSYnTwdzIgmG5VTC+BL59NmrPdO+DsC3w7JQxeHs/C/UwNCwQ41lQsccTR9xdpKEKghe1xLC4hVT9hf3BSLuTTDhGhuWMgOlm4Nubu4hjLKI7NEkVQpSdtBUQXyZMTAh9vu8zwE+kn8v9H6vjAHRCDixtjx7OMJ0BBr0C05CsZNvtdIaeLlogLhP4YODn5iNDRMRyrxDjOkBJ6Wc/ttkXdwJcY8LVumff+Ps1uibDLE4x8zmltQ6Emmhpr4noXuBnx7o1UcLuobsb1tTvfsTUz1ZDmwWhr0NpSYu3htEUG3vE+sX35fO/IoM+NH0ISpQArDKwSoDRpoqAgHUIp1AZWR59+QehKd6Zo8YgogAAAABJRU5ErkJggg==',
          symbolSize: [12, 15],
          showAllSymbol: true,
          // itemStyle: {
          //   normal: {
          //     show: true,
          //     // weight:28,
          //     // height:24
          //   }
          // },
          data: res,
        },
      })
    },
    ScrollUp() {
      this.intnum = setInterval((_) => {
        if (this.activeIndex < parseInt(this.prizeList.length / 4 - 4)) {
          this.activeIndex += 1
        } else {
          this.activeIndex = 0
        }
      }, 1000)
    },
    Stop() {
      clearInterval(this.intnum)
    },
    Up() {
      this.ScrollUp()
    },
    // 获取行情价格
    selectMarketPriceHomePage() {
      let data = {
        sql: this.hjjgarrone,
        num: 1,
        size: 6,
      }
      selectMarketPriceHomePageApi(data).then((res) => {
        if (res.code == 0) {
          this.data2 = res.data.list
        } else {
          // this.$Message.warning(res.errInfo)
        }
      })
    },
    // 获取废钢调价
    getPriceNews() {
      let data = {
        num: 1,
        size: 12,
      }
      getPriceNewsApi(data).then((res) => {
        if (res.code == 0) {
          this.fgtjarrs = res.data
        } else {
          // this.$Message.warning(res.errInfo)
        }
      })
    },
    // 获取物资拍卖
    getAuctionNewswz() {
      let data = {
        num: 1,
        size: 10,
        productId: 16230,
        name: 1,
      }
      getAuctionNewsApi(data).then((res) => {
        if (res.code == 0) {
          this.wzpmarrs = res.data
        } else {
          // this.$Message.warning(res.errInfo)
        }
      })
    },
    // 获取分析指导
    getAuctionNewsfx() {
      let data = {
        num: 1,
        size: 10,
        productId: 14953,
        name: 1,
      }
      getAuctionNewsApi(data).then((res) => {
        if (res.code == 0) {
          this.fxzdarrs = res.data
        } else {
          // this.$Message.warning(res.errInfo)
        }
      })
    },
    // 获取行业资讯
    getAuctionNewshy() {
      let data = {
        num: 1,
        size: 10,
        productId: 16242,
        name: 1,
      }
      getAuctionNewsApi(data).then((res) => {
        if (res.code == 0) {
          this.hyzxarrs = res.data
        } else {
          // this.$Message.warning(res.errInfo)
        }
      })
    },
    // 获取供求市场
    getItems() {
      let data = {
        pageNum: 1,
        pageSize: 12,
      }
      getItemsApi(data).then((res) => {
        if (res.code == 0) {
          this.gqscarrs = res.data.list
        } else {
          // this.$Message.warning(res.errInfo)
        }
      })
    },
    // 获取成交动态
    getUserOrders() {
      let data = {
        pageNum: 1,
        pageSize: 999,
      }
      getUserOrdersApi(data).then((res) => {
        if (res.code == 0) {
          this.prizeList = res.data.list
        } else {
          // this.$Message.warning(res.errInfo)
        }
      })
    },
    // 获取车辆动态
    homeInfocar() {
      let data = {
        command: 3,
      }
      homeInfo(data).then((res) => {
        if (res.code == 0) {
          this.cararrs = res.data.list
        } else {
          // this.$Message.warning(res.errInfo)
        }
      })
    },
    homeInfocar1() {
      let data = {
        command: 2,
      }
      homeInfo(data).then((res) => {
        if (res.code == 0) {
          this.carzaitunumber = res.data.list[0].trainNumber
        } else {
          // this.$Message.warning(res.errInfo)
        }
      })
    },
    // 获取车辆动态
    homeInfomap() {
      let data = {
        command: 1,
      }
      homeInfo(data).then((res) => {
        if (res.code == 0) {
          this.cargrpsnumber = res.data.list.length
          this.cargrps = res.data.list
          this.drawLine()
        } else {
          // this.$Message.warning(res.errInfo)
        }
      })
    },
    //点击钢厂调价跳转
    gcchanges(val) {
      if (this.token) {
        if (this.setVip == 1) {
          this.$router.push({ path: '/scrapdetail', query: { subId: val } })
        } else {
          this.$Modal.warning({
            title: '提示',
            content: '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
          })
        }
      } else {
        this.$router.push({ path: '/login' })
      }
    },
    // click hidden modal2
    confirmbtn() {
      this.modal2 = false;
    },
    //供求市场跳转
    selfset(quota, account) {
      if (this.token) {
        this.$router.push({
          path: '/supplyMarket/detail',
          query: { quota: quota, account: account },
        })
      } else {
        this.$router.push({ path: '/login' })
      }
    },
    handleImgClick(id, page, subjectId, marketId) {
      let data = {
        num:1,
        size:1,
        productId:14953,
        userName:this.userName,
        id:id
      }
      getAuctionNewsApi(data).then((res) => {
        if(res.data&&res.data.length>0){
        this.$router.push({
          name: 'analysisDel',
          query: {
            id: id,
            page:page,
            subjectId:subjectId,
            marketId:marketId
          },
        })
      }else{
        this.$Modal.warning({
          title: '提示',
          content:
            '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
        })
      }
    })
    },
    // 招标拍卖列表跳转
    bidAuctionclick(id, page, subjectId, marketId, name) {
      if (this.token) {
        if (this.setVip == 1) {
          this.$router.push({
            name: name,
            query: {
              id: id,
              page: page,
              subjectId: subjectId,
              marketId: marketId,
            },
          })
        } else {
          this.$Modal.warning({
            title: '提示',
            content: '<p>此服务为VIP专属，开通服务请致电</p><p>13329574939</p>',
          })
        }
      } else {
        this.$router.push({ path: '/login' })
      }
    },
    IndustryDetailclick(id, page, subjectId) {
      if (this.token) {
        this.$router.push({
          path: '/industry/detail',
          query: { id: id, page: page, subjectId: subjectId },
        })
      } else {
        this.$router.push({ path: '/login' })
      }
    },
  },
}
</script>

<style>
.ivu-carousel-dots {
  position: relative;
  top: -40px;
}
.ivu-carousel-dots li button {
  width: 14px !important;
  height: 14px !important;
  border: 1px solid #fff;
  background: none !important;
}
.swier .ivu-carousel-dots li.ivu-carousel-active > button {
  background-color: #fff !important;
}
</style>

<style scoped>
.advertisement {
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
}
.advertisement img {
  width: 386px;
}
.w960 {
  width: 816px;
}
.w420 {
  width: 357px;
}
.mbxstyle {
  line-height: 102px;
}
.mbxstyle img {
  margin-top: -10px;
}
.mbxstyle a,
.mbxstyle span {
  display: inline-block;
}
.mbxstyle span.mbx_title {
  font-size: 24px;
  color: #000;
  font-weight: bold;
  margin-left: 10px;
}
.mbxstyle .mbx_more {
  float: right;
  font-weight: 700;
  font-size: 14px;
  color: #000;
  margin-left: 30px;
  cursor: pointer;
}
.mbxstyle .mbx_more:hover {
  color: #25449a;
  text-decoration: underline;
}
.nametitle {
  height: 52px;
  line-height: 52px;
  border-bottom: 1px solid #25449a;
}
.nametitle li {
  float: left;
  width: 80px;
  text-align: center;
  font-size: 16px;
  color: #000;
  cursor: pointer;
}
.nametitle li.active {
  background: #25449a;
  color: #fff;
}
.tiaojiabody {
  height: 390px;
  border: 1px solid #e6ebf5;
  padding: 5px 8px;
  font-size: 14px;
  overflow: hidden;
}
.tiaojiabody li {
  line-height: 38px;
  cursor: pointer;
  font-size: 14px;
}
.tiaojiabody li span:nth-child(1) {
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tiaojiabody li:hover {
  color: #25449a;
}
.tiaojiabody li span.fl:before {
  content: '';
  width: 4px;
  height: 4px;
  display: inline-block;
  border-radius: 50%;
  background: #7c7c7c;
  vertical-align: middle;
  margin-right: 15px;
}
#myChart {
  border: 1px solid #bde3fd;
}
.cheliang .shuju span {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #000;
}
.cheliang .shuju .zaitu,
.cheliang .shuju .leiji {
  width: 50%;
  margin: 10px 0 41px;
}
.cheliang .shuju .zaitu span:nth-child(1) {
  font-size: 30px;
  color: #25449a;
  line-height: 45px;
}
.cheliang .shuju .leiji span:nth-child(1) {
  font-size: 24px;
  color: #000;
  line-height: 45px;
}
.scrolllist {
  height: 375px;
  border: 1px solid #e6ebf5;
}
.scrolllist .title {
  background: #e6ebf5;
}
.scrolllist li {
  background: #edf3fc;
}
.scrolllist li.active {
  background: #fef6ed;
}
.scrolllist span {
  float: left;
  width: 33.3%;
  text-align: center;
  line-height: 48px;
  font-size: 14px;
  border-bottom: 1px solid #e6ebf5;
}
.gongqiulist li {
  width: 25%;
  float: left;
  margin-bottom: 30px;
  cursor: pointer;
}
.gongqiulist li span {
  display: block;
  font-size: 14px;
}
.gongqiulist li span:nth-child(1) {
  font-size: 16px;
  margin-bottom: 5px;
}
.gongqiulist li span:nth-child(2),
.gongqiulist li span:nth-child(3) {
  line-height: 22px;
}
.gongqiulist li span:nth-child(4) {
  margin-top: 5px;
}
.zhuangtai .zhuangtailist {
  position: relative;
  transition: top 0.5s;
}
.zhuangtai .zhuangtailist li {
  font-size: 14px;
  line-height: 28px;
  height: 28px;
  width: 18%;
  float: left;
  overflow: hidden;
  margin: 0 11px;
}
.secondbody .secondlist > li {
  width: 389px;
  float: left;
}
.moresell {
  width: 148px;
}
.hehblist {
  text-align: center;
}
.hehblist li {
  display: inline-block;
  margin: 0 10px;
}
</style>
<style>
body {
  color: #000 !important;
}
.tubiao {
  cursor: pointer;
  display: block;
  width: 22px;
  height: 20px;
  background: url('~assets/img/tbpng.png');
  margin: 0 auto;
}
.ivu-table-cell {
  padding: 0;
}
.ivu-table-cell .up {
  display: inline-block;
  width: 10px;
  height: 13px;
  background: url('~assets/img/up.png');
  vertical-align: middle;
  margin-right: 3px;
}
.ivu-table-cell .down {
  display: inline-block;
  width: 10px;
  height: 13px;
  background: url('~assets/img/down.png');
  vertical-align: middle;
  margin-right: 3px;
}
.ivu-table-cell .red {
  color: #d31818;
}
.ivu-table-cell .green {
  color: #31aa24;
}
.ivu-table {
  font-size: 14px;
}
.swier .ivu-carousel-dots li {
  margin: 0 10px;
}
.swier .ivu-carousel-dots li button {
  height: 16px;
  border-radius: 50%;
  background: #fff;
  opacity: 1;
}
.swier .ivu-carousel-dots li.ivu-carousel-active > button {
  width: 16px;
  background: none;
  border: 1px solid #fff;
}
.homeone .ivu-table th {
  background-color: #e6ebf5;
}
.homeone .ivu-table-stripe .ivu-table-body tr:nth-child(2n) td,
.homeone .ivu-table-stripe .ivu-table-fixed-body tr:nth-child(2n) td {
  background-color: #f4f6fb;
}
.ivu-carousel-dots-inside {
  bottom: 15px;
  display: inline-block;
}
</style>
